import React, { useEffect, useRef, useState } from "react";
import { admin_url, apiurl, eventnameurl, laravel_asset, laravelapi, organizer_url } from '../../common/Helpers';
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, CardHeader, CardBody, Tooltip } from 'reactstrap';
import Draggable from 'react-draggable';
import InputColor from 'react-input-color';
import toast from "react-hot-toast";
import NoResult from '../../lotte/noresult.json';
import Step1img from '../../assets/step/1.png';
import Step2img from '../../assets/step/2.png';
import StepstImg from '../../assets/step/st.png';
import { FiLogOut } from "react-icons/fi";
import Lottie from "lottie-react";
import { FaArrowRotateRight } from "react-icons/fa6";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa6";
import { FaPenToSquare } from "react-icons/fa6";
const Type = () => {
    const lottewidth = {
        width: 'auto',
        height: '250px'
    }
    const navigate = useNavigate();
    const EventId = localStorage.getItem('event_edit_id');
    const [EditApiloader, setEditApiloader] = useState(false);
    const [SaveBtnLoader, setSaveBtnLoader] = useState(false);
    const [EventData, setEventData] = useState();
    const [TicketData, setTicketData] = useState([]);
    const [Uploadedimage, setUploadedimage] = useState();

    const [modal, setModal] = useState(false);
    const [addRowModal, setAddRowModal] = useState(false);
    const [image, setImage] = useState(null);
    const [NewImage, setNewImage] = useState(false);
    const [oldimage, setoldimage] = useState(null);
    const [StoreImage, setStoreImage] = useState(null);
    const [seatmapHeight, setSeatmapHeight] = useState(600);
    const [seatmapWidth, setSeatmapWidth] = useState(900);
    const [sections, setSections] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(null);
    const [rotationAngles, setRotationAngles] = useState({});
    const [borderColors, setBorderColors] = useState({});
    const [selectedSection, setSelectedSection] = useState(null);
    const [editingSection, setEditingSection] = useState(null);
    const [seatColor, setSeatColor] = useState('#005aff');
    const [zoomLevel, setZoomLevel] = useState(1);
    console.log(sections);
    const getEventData = async () => {
        try {
            setEditApiloader(true);
            const requestData = {
                id: EventId
            };
            fetch(apiurl + 'event/get-details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        const filteredData = data.data.allprice.filter(item => item.isdelete === 0);
                        setTicketData(filteredData);

                        setEventData(data.data);
                        if (data.data.pageheight) {
                            setSeatmapHeight(data.data.pageheight ? data.data.pageheight : null);
                        }
                        if (data.data.pageweight) {
                            setSeatmapWidth(data.data.pageweight ? data.data.pageweight : null);
                        }
                        if (data.data.seatmapimage) {
                            setImage(data.data.seatmapimage && laravel_asset + data.data.seatmapimage);
                        }
                    }
                    setEditApiloader(false);
                })
                .catch(error => {
                    console.error('Insert error:', error);
                    setEditApiloader(false);
                });

        } catch (error) {
            console.error('Api error:', error);
        }
    }
    const getSeatmapData = async () => {
        try {
            setEditApiloader(true);
            const requestData = {
                id: EventId
            };
            fetch(apiurl + 'event/getseatmap', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        if (data.data) {
                            const newSections = data.data.map(seat => ({
                                sectionId: seat.sectionId,
                                name: seat.sectionname,
                                rows: seat.rows,
                                seatsPerRow: seat.seatsPerRow,
                                ticketPrice: seat.ticketid, // Assuming ticketPrice should be set to ticketid
                                position: seat.position,
                                seatColor: seat.seatColor,
                                rotationAngle: seat.rotationAngle,
                                ticketName: seat.ticketdetails ? seat.ticketdetails.name : '',
                                ticketCurrentValue: seat.ticketdetails ? seat.ticketdetails.price : 0
                            }));
                            setSections(newSections);
                        }
                    }
                    setEditApiloader(false);
                })
                .catch(error => {
                    console.error('Insert error:', error);
                    setEditApiloader(false);
                });

        } catch (error) {
            console.error('Api error:', error);
        }
    }
    const HandelInsert = () => {
        if (NewImage) {
            uploadBannerToServer();
        } else {
            storeSeatmapData();
        }
    }
    const storeSeatmapData = async (imagename) => {
        try {
            setSaveBtnLoader(true);
            const requestData = {
                id: EventId,
                seatdata: sections,
                pageheight: seatmapHeight,
                pageweight: seatmapWidth,
                organizerid: EventData.organizer_id,
                seatmapimage: imagename ? imagename : null,
            };
            fetch(apiurl + 'event/seatmap-create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        console.log("data", data.data);
                        toast.success("Updated");
                    }
                    setSaveBtnLoader(false);
                })
                .catch(error => {
                    console.error('Insert error:', error);
                    setSaveBtnLoader(false);
                });

        } catch (error) {
            console.error('Api error:', error);
        }
    }
    const uploadBannerToServer = async () => {
        if (!NewImage) {
            return;
        }
        const formData = new FormData();
        formData.append('image', StoreImage); // 'image' is the parameter name expected by your API
        setSaveBtnLoader(true);
        try {
            const response = await fetch(laravelapi + 'upload-image', {
                method: 'POST',
                body: formData, // No headers needed, as FormData sets the Content-Type to multipart/form-data
            });

            if (!response.ok) {

                toast.error('Image not uploaded try again');
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Image uploaded successfully:', result);
            if (result) {
                setUploadedimage(result.image_name);
                setoldimage(image);
                storeSeatmapData(result.image_name);
                setNewImage(false);
            } else {
                return toast.error('Image not uploaded try again');
            }
            setSaveBtnLoader(false);
        } catch (error) {
            toast.error('Image not uploaded try again');
            console.error('Error uploading the image:', error);
            setSaveBtnLoader(false);
        }
    };
    useEffect(() => {
        if (EventId) {
            getEventData();
            getSeatmapData();
        }
    }, [EventId]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const toggleAddRowModal = () => {
        if (!seatmapHeight || !seatmapWidth) {
            toast.error("Add seatmap height and width from settings first");
            return;
        }
        setAddRowModal(!addRowModal);
        if (addRowModal) {
            setEditingSection(null); // Reset the editing section when closing the modal
        }
    };

    const handleImageChange = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        setStoreImage(event.target.files[0]);
        setNewImage(true);
    };

    const handleCreateSeatmap = () => {
        setModal(false);
    };
    const generateSectionId = () => {
        return Math.floor(10000 + Math.random() * 90000).toString();
    };
    const addSection = (event) => {
        event.preventDefault();
        const sectionData = {
            sectionId: generateSectionId(),
            name: event.target.sectionName.value,
            rows: parseInt(event.target.rows.value),
            seatsPerRow: parseInt(event.target.seatsPerRow.value),
            ticketPrice: event.target.ticketPrice.value,
            position: editingSection ? editingSection.position : { x: 0, y: 0 },
            seatColor: seatColor,
            rotationAngle: editingSection ? editingSection.rotationAngle : 0, // Add this line
            ticketName: TicketData.find(item => item.id === event.target.ticketPrice.value)?.name || '',
            ticketCurrentValue: TicketData.find(item => item.id === event.target.ticketPrice.value)?.price || 0
        };


        if (editingSection) {
            const updatedSections = sections.map(section => {
                if (section.sectionId === editingSection.sectionId) {
                    return { ...section, ...sectionData };
                }
                return section;
            });
            setSections(updatedSections);
        } else {
            setSections([...sections, sectionData]);
        }


        setAddRowModal(false);
        setEditingSection(null);
    };


    // const rotateSection = (sectionName) => {
    //   setRotationAngles({
    //     ...rotationAngles,
    //     [sectionName]: (rotationAngles[sectionName] || 0) + 2
    //   });
    // };
    const rotateSection = (sectionId, direction) => {
        const angleIncrement = direction === 'clockwise' ? 10 : -10;
        const updatedSections = sections.map(section => {
            if (section.sectionId === sectionId) {
                return { ...section, rotationAngle: (section.rotationAngle || 0) + angleIncrement };
            }
            return section;
        });
        setSections(updatedSections);
    };
    const rotateSectionD = (sectionId, direction) => {
        const angleIncrement = 45;
        const updatedSections = sections.map(section => {
            if (section.sectionId === sectionId) {
                return { ...section, rotationAngle: (section.rotationAngle || 0) + angleIncrement };
            }
            return section;
        });
        setSections(updatedSections);
    };
    const handleStop = (sectionId, position) => {
        const updatedSections = sections.map((section) => {
            if (section.sectionId === sectionId) {
                return { ...section, position: { x: position.x, y: position.y } };
            }
            return section;
        });
        setSections(updatedSections);
    };

    const deleteSection = (sectionId) => {
        setSections(sections.filter(section => section.sectionId !== sectionId));
        setSelectedSection(null);
    };

    const editSection = (sectionId) => {
        const sectionToEdit = sections.find(section => section.sectionId === sectionId);
        const ticketData = TicketData.find(item => item.id === sectionToEdit.ticketPrice);
        setEditingSection({
            ...sectionToEdit,
            ticketName: ticketData?.name || '',
            ticketCurrentValue: ticketData?.currentValue || 0
        });
        setSeatColor(sectionToEdit.seatColor);
        setAddRowModal(true);
    };


    const copySection = (sectionId) => {
        const sectionToCopy = sections.find(section => section.sectionId === sectionId); // Use sectionId for finding the section
        const copiedSection = {
            ...sectionToCopy,
            sectionId: generateSectionId(), // Generate a new sectionId
            // name: `${sectionToCopy.name}-${Math.floor(Math.random() * 10000)}`,
            name: `${sectionToCopy.name}`,
            position: { x: sectionToCopy.position.x + 20, y: sectionToCopy.position.y + 20 }
        };
        setSections([...sections, copiedSection]);
    };


    const zoomIn = () => {
        setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    };

    const zoomOut = () => {
        setZoomLevel((prevZoomLevel) => Math.max(1, prevZoomLevel - 0.1));
    };
    const Adminauth = localStorage.getItem('adminauth');
    const EditEvent = () => {
        const Ename = eventnameurl(EventData && EventData.display_name);
        const Id = eventnameurl(EventData && EventData._id);
        if (Adminauth) {
            navigate(`${admin_url}event/edit-event/${Id}/${Ename}`);
        } else {
            navigate(`${organizer_url}event/edit-event/${Id}/${Ename}`);
        }
    }
    return (
        <>
            <Row className="pb-2">
                <Col md={12}>
                    <div>
                        <Card>
                            <CardHeader>
                                {sections && sections.length == 0 && !image ? '' : (
                                    <div className="w-100">
                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xl-9">
                                                <Button className="btn theme-bg text-white m-1" onClick={toggleModal}>Change Image</Button>
                                                <Button className="btn theme-bg text-white m-1" onClick={toggleAddRowModal}>Add New Row</Button>
                                                {selectedSection && (
                                                    <>
                                                        {/* <Button className='m-1' color="info" onClick={() => rotateSection(selectedSection)}>Rotate</Button> */}
                                                        <Button className="btn theme-bg text-white m-1" onClick={() => rotateSection(selectedSection, 'counterclockwise')}><FaArrowRotateLeft /></Button>
                                                        <Button className="btn theme-bg text-white m-1" onClick={() => rotateSectionD(selectedSection)}>45°</Button>
                                                        <Button className="btn theme-bg text-white m-1" onClick={() => rotateSection(selectedSection, 'clockwise')}><FaArrowRotateRight /></Button>
                                                        <Button className="btn theme-bg text-white m-1" onClick={() => copySection(selectedSection)}><FaRegCopy /></Button>
                                                        <Button className="btn theme-bg text-white m-1" onClick={() => editSection(selectedSection)}><FaPenToSquare /></Button>
                                                        <Button className='m-1' color="danger" onClick={() => deleteSection(selectedSection)}><FaRegTrashCan /></Button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-2">
                                                {SaveBtnLoader ? (
                                                    <Button className='m-1 w-100' type="button" color="success">Wait</Button>
                                                ) : (
                                                    <Button className='m-1 w-100' type="button" color="success" onClick={() => HandelInsert()}>save</Button>
                                                )}
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-1">
                                                <Button className='m-1 w-100' type="button" color="dark" onClick={() => EditEvent()}><FiLogOut /></Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
                                    {sections && sections.length == 0 && !image ? (
                                        <>
                                            <div className="no-result-div text-center">
                                                <Lottie className="no-result-img" animationData={NoResult} style={lottewidth} />
                                                <div>
                                                    <div className="row seatmap-info">
                                                        <div className="col-12 my-2">
                                                            <img src={StepstImg} className="StepstImg"></img>
                                                        </div>
                                                        {/* <div className="col-12 col-lg-3"></div> */}
                                                        <div className="col-12 col-lg-12 text-center">
                                                            <p className="mb-0">1. Upload a picture of your floor map</p>
                                                        </div>
                                                        {/* <div className="col-12 col-lg-3"></div> *12}

                                                        {/* <div className="col-12 col-lg-3"></div> */}
                                                        <div className="col-12 col-lg-12 text-center">
                                                            <p className="mb-0">2. Add Seat Row</p>
                                                        </div>
                                                        {/* <div className="col-12 col-lg-3"></div> */}
                                                    </div>
                                                </div>
                                                <div className="text-center my-3">
                                                    <Button color="primary" className="btn theme-bg text-white" onClick={toggleModal}>Upload Seatmap Image</Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    width: `${seatmapWidth}px`, // Update this line
                                                    height: `${seatmapHeight}px`, // Update this line
                                                    // width: `${seatmapWidth * zoomLevel}px`, // Update this line
                                                    // height: `${seatmapHeight * zoomLevel}px`, // Update this line
                                                    backgroundImage: `url(${image})`,
                                                    backgroundSize: '100% 100%',
                                                    backgroundRepeat: 'no-repeat',
                                                    position: 'relative',
                                                    // transform: `scale(${zoomLevel})`,
                                                    transformOrigin: 'top left', // Add this line
                                                    margin: 'auto',
                                                    border: '1px solid #eee',
                                                    borderRadius: '3px',
                                                }}
                                            >
                                                {sections.map((section, index) => (
                                                    <Draggable
                                                        key={section.name}
                                                        position={section.position}
                                                        onStop={(e, data) => handleStop(section.sectionId, data)}
                                                        bounds="parent"
                                                        disabled={selectedSection !== null && selectedSection !== section.sectionId}
                                                    >
                                                        <div
                                                            onClick={() => setSelectedSection(section.sectionId)}
                                                            style={{
                                                                cursor: 'move',
                                                                width: `${section.seatsPerRow * 20}px`,
                                                                height: `${section.rows * 20}px`,
                                                                transformOrigin: 'center',

                                                            }}
                                                            id={`tooltip-${index}`}
                                                        >
                                                            <div
                                                                className={`seatmap_box ${selectedSection === section.sectionId && 'seatmap_box_shadow'}`}
                                                                style={{
                                                                    backgroundColor: selectedSection === section.sectionId ? 'lightblue' : '#eee',
                                                                    transform: `rotate(${section.rotationAngle || 0}deg)`,
                                                                }}
                                                            >
                                                                {Array.from({ length: section.rows }).map((_, rowIndex) => (
                                                                    <div key={rowIndex} style={{ display: 'flex' }}>
                                                                        {Array.from({ length: section.seatsPerRow }).map((_, seatIndex) => (
                                                                            <div className='seatmap_seat' key={seatIndex} style={{ backgroundColor: section.seatColor }}></div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={tooltipOpen === section.sectionId}
                                                                    target={`tooltip-${index}`}
                                                                    toggle={() => setTooltipOpen(tooltipOpen === section.sectionId ? null : section.sectionId)}
                                                                >
                                                                    {`Section: ${section.name}, Rows: ${section.rows}, Seats/Row: ${section.seatsPerRow}, Ticket: ${section.ticketName}, Price:  ${EventData && EventData.countrysymbol} ${section.ticketCurrentValue}`}
                                                                </Tooltip>

                                                            </div>
                                                        </div>
                                                    </Draggable>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>

                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Select Image and Dimensions</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="imageFile">Image</Label>
                        <Input type="file" name="image" id="imageFile" onChange={handleImageChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="height">Height</Label>
                        <Input type="number" name="height" id="height" placeholder="Enter height" value={seatmapHeight} onChange={(e) => setSeatmapHeight(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="width">Width</Label>
                        <Input type="number" name="width" id="width" placeholder="Enter width" value={seatmapWidth} onChange={(e) => setSeatmapWidth(e.target.value)} />
                    </FormGroup>
                    <Button className="btn theme-bg text-white" onClick={handleCreateSeatmap}>Submit</Button>
                </ModalBody>
            </Modal>

            <Modal isOpen={addRowModal} toggle={toggleAddRowModal}>
                <ModalHeader toggle={toggleAddRowModal}>Add Row</ModalHeader>
                <ModalBody>
                    <form onSubmit={addSection}>
                        <FormGroup>
                            <Label for="sectionName">Section Name</Label>
                            <Input type="text" name="sectionName" id="sectionName" placeholder="Enter section name" defaultValue={editingSection ? editingSection.name : ''} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="rows">Number of Rows</Label>
                            <Input type="number" name="rows" id="rows" placeholder="Enter number of rows" defaultValue={editingSection ? editingSection.rows : ''} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="seatsPerRow">Seats Per Row</Label>
                            <Input type="number" name="seatsPerRow" id="seatsPerRow" placeholder="Enter seats per row" defaultValue={editingSection ? editingSection.seatsPerRow : ''} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="ticketPrice">Select Ticket</Label>
                            <Input type="select" name="ticketPrice" id="ticketPrice" required defaultValue={editingSection ? editingSection.ticketPrice : ''}>
                                {TicketData && TicketData.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        {/* <FormGroup>
              <Label for="borderColor">Border Color</Label>
              <Input type="text" name="borderColor" id="borderColor" placeholder="Enter border color (hex)" defaultValue={editingSection ? editingSection.borderColor : ''} />
            </FormGroup> */}
                        <FormGroup>
                            <FormGroup>
                                <Label for="seatColor">Seat Color</Label>
                                <div>
                                    <InputColor
                                        initialValue={seatColor}
                                        onChange={(color) => setSeatColor(color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </FormGroup>

                        </FormGroup>

                        <Button type="submit" color="primary">{editingSection ? 'Update Section' : 'Add Section'}</Button>
                    </form>

                </ModalBody>
            </Modal>
        </>
    )
}
export default Type;