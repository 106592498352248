import React, { useEffect } from "react";
import Footer from './footer';
import HeaderMenu from './headermenu';
import MobileMenu from './mobilemenu';
import Accordion from 'react-bootstrap/Accordion';
const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <!-- mobile nav --> */}
      <div className="content-area faq-content-area">
        <HeaderMenu />
        <div className="mx-lg-4 my-lg-3 banner bg-primary-color rounded-8 position-relative">
          <MobileMenu />
          <h1 className="banner-h text-white text-uppercase fw-bold pb-0 animate__animated animate__bounce">
            FAQ’s
          </h1>
        </div>
        <div className="banner-child bg-white p-3" style={{
    marginTop: '-265px',
    position: 'relative' }}>
            <Accordion defaultActiveKey="0" className=" mx-4 my-5">
              <Accordion.Item eventKey="0" className="faq-acc-item">
                <Accordion.Header>How can I purchase tickets?</Accordion.Header>
                <Accordion.Body>
                You can purchase tickets on our website.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="faq-acc-item">
                <Accordion.Header>What payment methods do you accept?</Accordion.Header>
                <Accordion.Body>
                We accept PayNow, Visa, Mastercard, American Express, and others.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="faq-acc-item">
                <Accordion.Header>How will I receive my tickets?</Accordion.Header>
                <Accordion.Body>
                E-tickets will be emailed to you in the form of QR codes. You can also find your tickets under “My Orders” section on your profile in the form of a QR code.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="faq-acc-item">
                <Accordion.Header>What is your refund/exchange policy?</Accordion.Header>
                <Accordion.Body>
                Tickets are non-refundable. In case the event gets canceled, the tickets will be refunded from the organizer’s end. In case the event gets postponed, the tickets will be valid for the next selected date of the event. TIXME fee is non-refundable in all circumstances. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="faq-acc-item">
                <Accordion.Header>My event was postponed/rescheduled. Can I get a refund?
                </Accordion.Header>
                <Accordion.Body>
                If an event is rescheduled, your ticket will be valid for the new date. If you cannot attend the new date, you can request a full refund. You will, however, not receive a refund on the TIXME service fees.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="faq-acc-item">
                <Accordion.Header>I lost my tickets. Can I get replacements?
                </Accordion.Header>
                <Accordion.Body>
                Lost or misplaced tickets can be reissued for a $5 fee per ticket. Provide your order confirmation number when you contact us. However, your ticket should always be available in your account under the section “My Orders” electronically. 

                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="6" className="faq-acc-item">
                <Accordion.Header>How do I know if my tickets are valid?
                </Accordion.Header>
                <Accordion.Body>
                Check your tickets carefully to ensure the event name, date, seat location and barcode match your purchase confirmation. Contact us immediately if there are any discrepancies and we will help you resolve the issue. 

                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="7" className="faq-acc-item">
                <Accordion.Header>How do I know about any change in event notifications? 

                </Accordion.Header>
                <Accordion.Body>
                Keep a look at your mailbox to know any changes to the event from your event organizer. You will also receive emails from us in case of any changes. 


                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="8" className="faq-acc-item">
                <Accordion.Header>What is your policy for lost and found items?

                </Accordion.Header>
                <Accordion.Body>
                If you lose an item at one of our venues, please check with guest services or the ushers in the area you were sitting. They will assist you in retrieving your lost item if it has been turned in. TIXME takes no responsibility for any lost item at any of our events. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9" className="faq-acc-item">
                <Accordion.Header>Can I transfer my tickets to someone else?</Accordion.Header>
                <Accordion.Body>
                Yes, tickets are transferable. Contact our customer support to facilitate the transfer with the necessary information. Or you can visit “My Orders” section in your account settings and transfer it to someone with an existing TIXME account by filling in their details. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10" className="faq-acc-item">
                <Accordion.Header>Are there any additional fees beyond the ticket price?
                </Accordion.Header>
                <Accordion.Body>
                TIXME charges a service fee, which is included in the total price during checkout.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="11" className="faq-acc-item">
                <Accordion.Header>Can I upgrade or change my seats after purchasing tickets?
                </Accordion.Header>
                <Accordion.Body>
                Seat upgrades or changes are subject to availability and must be coordinated through our customer support.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="12" className="faq-acc-item">
                <Accordion.Header>Do you offer group discounts?
                </Accordion.Header>
                <Accordion.Body>
                Yes, we offer group discounts for certain events. Contact our customer support for more information.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="13" className="faq-acc-item">
                <Accordion.Header>How do I redeem a promotional code or discount voucher?
                </Accordion.Header>
                <Accordion.Body>
                Enter the code during the checkout process to apply for the discount. Make sure to check the terms and conditions for each code.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="14" className="faq-acc-item">
                <Accordion.Header>Can I get a refund for a weather-related cancellation?
                </Accordion.Header>
                <Accordion.Body>
                Weather-related cancellations are subject to the organizer's policies. Refunds will be processed according to their terms.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="15" className="faq-acc-item">
                <Accordion.Header>How do I contact customer support?
                </Accordion.Header>
                <Accordion.Body>
                You can reach our customer support team through the "Contact Us" section on our website or via email and phone during business hours. You also have 24/7 access to a chatbot that can help you with generic issues.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="16" className="faq-acc-item">
                <Accordion.Header>Can I purchase tickets for events happening in other cities or countries?
                </Accordion.Header>
                <Accordion.Body>
                Yes, our platform allows you to purchase tickets for events in all our participating countries. Check the event details for location information.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="17" className="faq-acc-item">
                <Accordion.Header>What security measures are in place for online transactions?

                </Accordion.Header>
                <Accordion.Body>
                We prioritize the security of your transactions. Our website uses encrypted technology to protect your personal and payment information.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="18" className="faq-acc-item">
                <Accordion.Header>Can I get a receipt for my ticket purchase?
                </Accordion.Header>
                <Accordion.Body>
                Yes, a confirmation email with your receipt will be sent to the email address provided during the purchase. You can also find your receipt in the "My Orders" section of your profile.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="19" className="faq-acc-item">
                <Accordion.Header>How do I unsubscribe from marketing emails?
                </Accordion.Header>
                <Accordion.Body>
                You can manage your email preferences in your account settings or use the "unsubscribe" link in the marketing emails.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="20" className="faq-acc-item">
                <Accordion.Header>Do you offer a loyalty program for frequent buyers?
                </Accordion.Header>
                <Accordion.Body>
                Yes, we have a loyalty program that rewards frequent buyers with exclusive perks and discounts. Check the loyalty program section on our website for details.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
