import React, { useEffect } from "react";
import Footer from './footer';
import HeaderMenu from './headermenu';
import MobileMenu from './mobilemenu';
const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="content-area">
        {/* <!-- mobile nav --> */}
        <HeaderMenu />
        <div class="mx-lg-4 my-lg-3 banner-events-page bg-primary-color rounded-8 position-relative">
          <MobileMenu />
          <h1 class="banner-h banner-h-events text-white text-start text-uppercase animate__animated animate__bounce">
            Purchase Policy
          </h1>
        </div>
        <div
          className="event-view-body"
        >
          <div className="mx-3">
            <h4>
            Welcome to TIXME! Before purchasing tickets, please read our Purchase Policy carefully to ensure a smooth and enjoyable ticketing experience.
            </h4>
            <br/>
            <h5>1. Ticket Availability</h5>
            <p>
            Tickets are sold on a first-come, first-served basis. Availability is subject to change until the purchase is complete
            </p>


            <h5>2. Pricing</h5>
            <p>
            Ticket prices are subject to change without notice. Prices may vary based on event type, location, date, and availability
            </p>


            <h5>3. Payment Methods</h5>
            <h6>We accept the following payment methods:
            </h6>  
            <ul>
              <li>
              PayNow
              </li>
              <li>
              Visa
              </li>
              <li>
              Mastercard
              </li>
              <li>
              American Express

              </li>
              <li>
              Apple Pay

              </li>
              <li>Google Pay
              </li>
         
            </ul>
            <h5>4. Order Confirmation</h5>
            <p>
            Upon successful payment, you will receive an email confirmation with your order details and a digital ticket. Please check your spam/junk folder if you do not see the email in your inbox.

            </p>


            <h5>5. Refunds and Exchanges</h5>
            <p>
            All ticket sales are final. Refunds, exchanges, or cancellations are not permitted unless an event is canceled or rescheduled. In the case of event cancellation, you will be notified via email with instructions on how to obtain a refund
            </p>



            <h5>6. Rescheduled Events</h5>
            <p>
            If an event is rescheduled, your ticket will be valid for the new date. If you are unable to attend the rescheduled date, please contact our customer support for further assistance.

            </p>



            <h5>7. Lost or Stolen Tickets</h5>
            <p>
            TIXME is not responsible for lost or stolen tickets. Digital tickets can be reissued by accessing your account on our platform.

            </p>



            <h5>8. Ticket Limits</h5>
            <p>
            For certain events, ticket limits may apply. These limits are in place to ensure fair access to tickets for all customers. Exceeding the ticket limit may result in order cancellation.

            </p>



            <h5>9. Delivery Methods
            </h5>
            <p>
            Tickets will be delivered electronically to the email address provided during the purchase process. Ensure that you enter a valid email address.

            </p>


            <h5>10. Event Entry</h5>
            <p>
            Entry to events requires a valid ticket. Digital tickets should be presented on your mobile device or printed out for scanning at the venue.

            </p>


            <h5>11. Event Changes</h5>
            <p>
            Event details, including date, time, location, and performers, are subject to change. TIXME is not responsible for any changes or discrepancies in event details.

            </p>


            <h5>12. Customer Support
            </h5>
            <p>
            If you encounter any issues or have questions about your purchase, please contact our customer support team at tixme.team@gmail.com.

            </p>


            <h5>13. Compliance with Venue Policies</h5>
            <p>
            All attendees must comply with the venue's rules and regulations, including any COVID-19 safety measures or restrictions.

            </p>


            <h5>14. Prohibited Conduct</h5>
            <p>
            The resale of tickets for profit is prohibited. Any tickets identified as being resold in violation of this policy may be canceled without notice.

            </p>

            <p>By purchasing tickets from TIXME, you agree to abide by this Purchase Policy. We strive to provide the best ticketing experience and appreciate your cooperation.
            </p>

            <p>Thank you for choosing TIXME!
            </p> 
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
