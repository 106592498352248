import React, { useEffect } from "react";
import Footer from './footer';
import HeaderMenu from './headermenu';
import MobileMenu from './mobilemenu';
const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="content-area">
        {/* <!-- mobile nav --> */}
        <HeaderMenu />
        <div class="mx-lg-4 my-lg-3 banner-events-page bg-primary-color rounded-8 position-relative">
          <MobileMenu />
          <h1 class="banner-h banner-h-events text-white text-start text-uppercase animate__animated animate__bounce">
            Privacy Policy
          </h1>
        </div>
        <div
          className="event-view-body"
        >
          <div className="mx-3">
            <h1><strong>Last Updated: 7 August 2024</strong></h1>
            <p>
        Welcome to TIXME, your trusted ticketing partner. We are committed to protecting your privacy
        and ensuring the security of your personal data. This Privacy Policy explains how we collect,
        use, and share your personal information. By using our services, you agree to the practices
        outlined in this policy.
      </p>
      
      <h2>1. Information We Collect</h2>
      <h3>a. For Company's Use:</h3>
      <ul>
        <li><strong>A.1)</strong> Contact Information: Name, email address, phone number.</li>
        <li><strong>A.2)</strong> Account Information: Username, password, and account preferences.</li>
        <li><strong>A.3)</strong> Payment Information: Credit card details or other payment information.</li>
        <li><strong>A.4)</strong> Transaction Data: Details of your ticket purchases, including event information and purchase history.</li>
        <li><strong>A.5)</strong> Communication Data: Records of your correspondence with us, including emails and support inquiries.</li>
        <li><strong>A.6)</strong> Usage Data: Information about your interactions with our platform, such as pages visited and features used.</li>
        <li><strong>A.7)</strong> Device and Location Information: Device data (e.g., IP address, browser type) and location data if you enable location services.</li>
      </ul>
      
      <h3>b. For Provider’s, Venue Host’s, & Merchant’s Use:</h3>
      <ul>
        <li><strong>B.1)</strong> Transaction Data: Information about your ticket purchases, including event details and ticket types, shared with our Provider, Venue Host, and Merchant partners for event planning and communication purposes.</li>
        <li><strong>B.2)</strong> Contact Information: Used for ticket delivery and event-related updates.</li>
      </ul>
      
      <h2>2. How We Use Your Information</h2>
      <h3>a. For Company's Use:</h3>
      <ul>
        <li><strong>A.1)</strong> To provide and enhance our services.</li>
        <li><strong>A.2)</strong> To process payments and complete ticket transactions.</li>
        <li><strong>A.3)</strong> To communicate with you, including service-related notifications.</li>
        <li><strong>A.4)</strong> To personalize your experience and provide tailored content and recommendations.</li>
        <li><strong>A.5)</strong> To respond to inquiries, resolve issues, and provide customer support.</li>
      </ul>
      
      <h3>b. For Provider’s, Venue Host’s, & Merchant’s Use:</h3>
      <ul>
        <li><strong>B.1)</strong> To facilitate event planning, ticket distribution, and event-related communications.</li>
        <li><strong>B.2)</strong> To manage ticket sales, capacity planning, and attendee information.</li>
        <li><strong>B.3)</strong> To fulfill event-specific requests or requirements.</li>
        <li><strong>B.4)</strong> To send event updates, promotions, and offers, if you have opted in to receive such communications.</li>
      </ul>
      
      <h2>3. Sharing of Your Information</h2>
      <ul>
        <li><strong>3.1)</strong> Provider, Venue Host, and Merchant partners for event-related purposes.</li>
        <li><strong>3.2)</strong> Service providers who assist us with various functions, such as payment processing, data analysis, and customer support.</li>
        <li><strong>3.3)</strong> Other third parties as required by law or to protect our rights and interests.</li>
      </ul>
      
      <h2>4. Cookies and Similar Technologies</h2>
      <h3>a. Cookies:</h3>
      <p>
        We and our partners may use cookies, which are small text files stored on your device when you use our website or services. Cookies help us improve your user experience,
        remember preferences, and analyze service usage.
      </p>
      <h3>b. Opting Out:</h3>
      <p>
        You can manage your cookie preferences through your browser settings. Please note that disabling cookies may limit certain functionalities.
      </p>
      
      <h2>5. Marketing Opt-In and Opt-Out Provisions</h2>
      <p>
        You have the option to opt in or opt out of receiving marketing communications from us. You
        can manage your preferences in your account settings or by following the instructions provided
        in our marketing communications.
      </p>
      
      <h2>6. Legal Basis for Company Processing Your Personal Data</h2>
      <ul>
        <li><strong>6.1)</strong> Contractual necessity for providing our services.</li>
        <li><strong>6.2)</strong> Legitimate interests, such as improving our services and protecting our business interests.</li>
        <li><strong>6.3)</strong> Consent, when you opt in to receive marketing communications.</li>
      </ul>
      
      <h2>7. Sharing with and Disclosure to Third Parties</h2>
      <p>
        We may share your data with third parties, including our Provider, Venue Host, and Merchant
        partners, and service providers, as described in this policy.
      </p>
      
      <h2>8. Transfers of Personal Data</h2>
      <p>
        If you are based in the UK, EEA, India, USA, or Singapore, your personal data may be
        transferred and processed outside your jurisdiction. We ensure such transfers comply with
        applicable data protection laws.
      </p>
      
      <h2>9. Social Media</h2>
      <p>
        When you use social media features on our platform, your interactions may be subject to the
        privacy policies of the respective social media platforms.
      </p>
      
      <h2>10. Security</h2>
      <p>
        We employ security measures to protect your data. However, please understand that no system
        can guarantee complete security.
      </p>
      
      <h2>11. Records Retention</h2>
      <p>
        We retain your personal data for as long as necessary to fulfill the purposes outlined in this
        policy, unless a longer retention period is required by law.
      </p>
      
      <h2>12. Accessing and Updating Your Data</h2>
      <p>
        You can access, correct, or delete your personal data by contacting us at tixme.team@gmail.com.
        We will respond to your request within a reasonable timeframe.
      </p>
      
      <h2>13. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy or our data practices, please contact
        us at <a href="mailto:tixme.team@gmail.com"><span style={{ fontWeight: 400 }}>tixme.team@gmail.com</span></a>
      </p>
      
      <p>
        Our privacy policy is made as per the laws and regulations of the following states and aims to
        follow all diligently:
      </p>
      <h3>United States:</h3>
      <p>
        The U.S. does not have a comprehensive federal privacy law but has various sector-specific laws (e.g., HIPAA, CCPA, etc.) that apply to specific industries and types of data.
        Additionally, some states have enacted their own data protection laws. Compliance with state
        and federal laws is essential.
      </p>
      
      <h3>United Kingdom:</h3>
      <p>
        The UK has its own data protection laws following Brexit. The primary regulation is the UK Data Protection Act 2018, which is aligned with the General Data Protection Regulation (GDPR).
      </p>
      
      <h3>India:</h3>
      <p>
        Ensuring compliance with the PDPB and other Indian regulations is critical.
      </p>
      
      <h3>Singapore:</h3>
      <p>
        Singapore has its data protection law, the Personal Data Protection Act (PDPA), which governs the collection, use, and disclosure of personal data. Compliance with the PDPA is
        essential for businesses operating in Singapore.
      </p>
      
      <p>Thank you for trusting TIXME. We are committed to safeguarding your privacy and providing
        you with the best ticketing experience.
      </p>

            
            
           
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
