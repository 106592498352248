// GoogleLoginComponent.jsx

import React from 'react';
import { auth, googleProvider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';

const GoogleLoginComponent = () => {
    

    return (
        <></>
    );
};

export default GoogleLoginComponent;
