import React, { useEffect, useState } from "react";
import JoinStartButton from "../../../common/elements/JoinStartButton";
import whitestar from '../../../common/icon/whitestar.svg';
import { Button, Col, Row } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Norecord from '../../../component/Norecordui';
import toast from 'react-hot-toast';
import { apiurl, admin_url } from '../../../common/Helpers';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { FiEdit2, FiPlus } from "react-icons/fi";
import {
    Modal,
    Input,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiTrash2 } from "react-icons/fi";
const Dashboard = ({ title }) => {
    const [Loader, setLoader] = useState(false);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [Addnewmodal, setAddnewmodal] = useState(false);
    const [List, setList] = useState([]);
    const [name, setname] = useState();
    const [Upid, setUpid] = useState();
    const [point, setpoint] = useState();
    const [discount, setdiscount] = useState();
    const [currencyList, setcurrencyList] = useState([{ value: "", label: "Currency" }]);
    const [Currency, setCurrency] = useState();
    const [CurrencyId, setCurrencyId] = useState();
    const [type, setType] = useState('amount'); // Default to 'amount'
    const [limitType, setLimitType] = useState('unlimited'); // Default to 'unlimited'
    const [limitNumber, setLimitNumber] = useState('');
    const [discountamout, setdiscountamout] = useState();
    const [eventList, seteventList] = useState([]);
    const [selecteventFor, setselecteventFor] = useState();
    const [TicketType, setTicketType] = useState([]);
    const [selecteventForTicketType, setselecteventForTicketType] = useState();
    const handleChange = (selectedOption) => {
        // Check if selectedOption is defined
        if (selectedOption) {
            setselecteventFor(selectedOption); // Update the selected event
            
            // Only proceed if the selected event's value is not 1
            if (selectedOption.value !== 1) {
                const requestData = {
                    eventid: selectedOption.value,
                };
    
                fetch(apiurl + 'event/events/eventwiseticket', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                })
                    .then(response => response.json())
                    .then(data => {
                        const globalTicketType = {
                            value: 1,          // Set the value for global ticket
                            label: 'Global Ticket' // Label for global ticket
                        };
    
                        // Ensure data.data[0].allprice exists
                        if (data.data && data.data[0] && data.data[0].allprice) {
                            const transformedDataTicketType = data.data[0].allprice.map(ticket => ({
                                value: ticket.id,    // Use `id` as value
                                label: ticket.name   // Use `name` as label
                            }));
                            const combinedOptionsTicketType = [globalTicketType, ...transformedDataTicketType];
                            setTicketType(combinedOptionsTicketType);
                        } else {
                            toast.error('No ticket types available for this event.');
                        }
                    })
                    .catch(error => {
                        toast.error('Insert error: ' + error.message);
                        console.error('Insert error:', error);
                    });
            }
        } else {
            console.error('Selected option is undefined');
        }
    };
      const handleChangeTicketType = (selectedOptionTicketType) => {
        setselecteventForTicketType(selectedOptionTicketType);
      };
      
    const CurrencyOption = [
        {
            options: currencyList
        }
    ]
    const fetchCountry = async () => {
        try {
            fetch(apiurl + 'admin/country-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        const countryData = data.data;
                        const currencyOption = countryData.map(item => ({
                            value: item.currency,
                            label: item.symbol
                        }));
                        setcurrencyList(currencyOption)
                    }
                })
                .catch(error => {
                    console.error('Insert error:', error);
                });
        } catch (error) {
            console.error('Api error:', error);
        }
    }
    const selectCurrency = (selectedValue) => {
        setCurrency(selectedValue);
        setCurrencyId(selectedValue.label);
    };
    const Fetchlist = async () => {
        try {
            setLoader(true);
            fetch(apiurl + 'admin/get-eventcoupon-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        setList(data.data);
                    } else {
                        toast.error(data.message);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    setLoader(false);
                    toast.error('error: ' + error.message);
                    console.error('Insert error:', error);
                });
        } catch (error) {
            setLoader(false);
            console.error('Api error:', error);
        }
    };
    const HandelGetEvent = () => {

        try{
            fetch(apiurl + 'admin/event-list-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
            })
                .then(response => response.json())
                .then(data => {
                  
                    const globalTaxOption = {
                        value: 1,          // Set the value for global tax
                        label: 'Global Event' // Label for global tax
                      };
                 
                    const transformedData = data.data.map(ticket => ({
                        value: ticket._id,    // Use `id` as value
                        label: ticket.display_name   // Use `name` as label
                    }));
                    const combinedOptions = [globalTaxOption, ...transformedData];

                    seteventList(combinedOptions);
                })
                .catch(error => {
                    toast.error('Insert error: ' + error.message);
                    console.error('Insert error:', error);
                });
        }catch(error){
            toast.error(error.message);
        }
    }
    useEffect(() => {
        Fetchlist();
        HandelGetEvent();
    }, []);
    const HandelCreateCoupon = async () => {
        try {
            if (!name || !type || !discount || !limitType) {
                return toast.error('All field is required');
            }
            setBtnLoader(true);
            const requestData = {
                upid: Upid ? Upid : null,
                name: name,
                type: type,  // Include the type (either 'amount' or 'percentage')
                discount: discount,
                limitType: limitType, // Either 'limited' or 'unlimited'
                limitNumber: limitType === 'limited' ? limitNumber : undefined,
                eventvalue:selecteventFor.value,
                eventlabel:selecteventFor.label,
                ticketvalue: selecteventForTicketType?.value || null,
                ticketlabel: selecteventForTicketType?.label || null,
            };
        
            fetch(apiurl + 'admin/coupon-event-insert', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        toast.success('Created successful', {
                            duration: 3000,
                        });
                        setname('')
                        setType('amount');
                        setdiscount();
                        setLimitType('unlimited');
                        setLimitNumber('');
                        Fetchlist();
                        setUpid("");
                    } else {
                        toast.error(data.message);
                    }
                    setBtnLoader(false);
                })
                .catch(error => {
                    setBtnLoader(false);
                    toast.error('error: ' + error.message);
                    console.error('Insert error:', error);
                });
        } catch (error) {
            setBtnLoader(false);
            console.error('Api error:', error);
        }
    };
    const MySwal = withReactContent(Swal);
   


    const CheckEdit = async (id) => {
        try {
            const requestData = {
                id: id
            };
            fetch(apiurl + 'admin/coupon-event-details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    setUpid(data.data._id);
                    setname(data.data.name);
                    setType(data.data.type);
                    setdiscount(data.data.discount);
                    setLimitType(data.data.limitType);
                    setLimitNumber(data.data.limitNumber);
                    setselecteventFor({ value: data.data.eventvalue, label: data.data.eventlabel });
                    setselecteventForTicketType({ value: data.data.ticketvalue, label: data.data.ticketlabel });
                    setAddnewmodal(true);
                })
                .catch(error => {
                    console.error('Insert error:', error);
                });
        } catch (error) {
            console.error('Api error:', error);
        }
    }

    function CheckDelete(id) {
        MySwal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                HandelDelete(id)
            } else if (result.isDenied) {

            }
        })
    }
    const HandelDelete = async (id) => {
        try {
            setLoader(true)
            const requestData = {
                id: id
            };
            fetch(apiurl + 'admin/coupon-event-delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the Content-Type header to JSON
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        toast.success('Deleted successfully');
                        Fetchlist();
                    } else {
                        toast.error(data.error);
                    }
                    setLoader(false)
                })
                .catch(error => {
                    console.error('error:', error);
                    setLoader(false)
                });
        } catch (error) {
            console.error('Api error:', error);
            setLoader(false)
        }
    }
    const openModal = async () => {
        setname('')
        setType('amount');
        setdiscount();
        setLimitType('unlimited');
        setLimitNumber('');
        setUpid("");
        setAddnewmodal(!Addnewmodal)
    }
    return (
        <>
      <Modal isOpen={Addnewmodal} toggle={() =>openModal()} className='modal-dialog-centered'>
  <ModalHeader toggle={() => openModal()} className='bg-transparent'>
  {Upid ? 'Update' : 'Create'} New Coupon
  </ModalHeader>
  <ModalBody className=''>
    {Loader ? (
      <div className="linear-background w-100"></div>
    ) : (
      <div className="row">
        {/* Coupon Name */}
        <div className="col-md-12 mb-2">
          <label htmlFor="" className="text-black">Coupon Name</label>
          <input 
            type="text" 
            className="form-control input-default" 
            placeholder="Enter coupon name" 
            value={name} 
            onChange={(e) => setname(e.target.value)} 
          />
        </div>

        {/* Amount or Percentage */}
        <div className="col-md-12 mb-2">
          <label htmlFor="" className="text-black">Type</label>
          <select 
            className="form-control input-default" 
            value={type} 
            onChange={(e) => setType(e.target.value)}
          >
            <option value="amount">Amount</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        {/* Discount Amount */}
        <div className="col-md-12 mb-2">
          <label htmlFor="" className="text-black">{type === 'amount' ? 'Discount Amount' : 'Discount Percentage'}</label>
          <input 
            type="number" 
            className="form-control input-default" 
            placeholder={type === 'amount' ? 'Enter discount amount' : 'Enter discount percentage'} 
            value={discount} 
            onChange={(e) => setdiscount(e.target.value)} 
          />
        </div>

        {/* Limit or Unlimited */}
        <div className="col-md-12 mb-2">
          <label htmlFor="" className="text-black">Limit</label>
          <select 
            className="form-control input-default" 
            value={limitType} 
            onChange={(e) => setLimitType(e.target.value)}
          >
            <option value="unlimited">Unlimited</option>
            <option value="limited">Limited</option>
          </select>
        </div>

        {/* Limit Number Input (Conditional) */}
        {limitType === 'limited' && (
          <div className="col-md-12 mb-2">
            <label htmlFor="" className="text-black">Limit Number</label>
            <input 
              type="number" 
              className="form-control input-default" 
              placeholder="Enter limit number" 
              value={limitNumber} 
              onChange={(e) => setLimitNumber(e.target.value)} 
            />
          </div>
        )}
     <div className="col-md-12 mt-3">
                                        <label htmlFor="">Select Event<span className="text-danger">*</span></label>
                                        <Select
                                            className="react-select"
                                            onChange={handleChange}
                                            value={selecteventFor}
                                            options={eventList}
                                        />
                                    </div>
                                    {selecteventFor && selecteventFor.value != 1 && (
                <div className="mt-3">
                    <label htmlFor="additional-input">Select Ticket Type</label>
                    <Select
                                            className="react-select"
                                            onChange={handleChangeTicketType}
                                            value={selecteventForTicketType}
                                            options={TicketType}
                                        />
                </div>
            )}
        {/* Submit Button */}
        <div className="col-md-12 mt-3">
          {BtnLoader ? (
            <button className="w-100 theme-btn">
              <span>Please wait...</span>
            </button>
          ) : (
            <button className="w-100 theme-btn" onClick={() => HandelCreateCoupon()}>
              <span className="theme-btn-icon"><FiPlus /></span> 
              <span>{Upid ? 'Update' : 'Create'} Coupon</span>
            </button>
          )}
        </div>
      </div>
    )}
  </ModalBody>
</Modal>
            <div className="content-body" style={{ background: '#F1F1F1' }}>
                <div className="container-fluid">
                    <Row className="mt-2">
                        <Col md={12}>
                            <Card className="">
                                <Card.Header className="d-flex justify-content-end">
                                    <button type="button" className="btn theme-bg text-white" onClick={() => setAddnewmodal(!Addnewmodal)}><span className="theme-btn-icon"><FiPlus /></span> Add New</button>
                                </Card.Header>
                                <Card.Body className="py-4">
                                    <Row className="">
                                        {Loader ? (
                                            <div className="linear-background w-100"> </div>
                                        ) : (
                                            <>
                                                {List.length > 0 ? (
                                                    <>
                                                        {List.map((item, index) => (
                                                            <Col md={4} className="mb-3">
                                                                <div className="coupon-page-ui shadow">
                                                                    <div className="row align-items-stretch align-items-center">
                                                                        <div className="d-flex align-items-center col-md-4 text-center justify-content-center">
                                                                            <p className="coupon-amount">{item.discount}</p>
                                                                            
                                                                            <p className="coupon-amount" style={{fontSize:10}}> {item.type}</p>
                                                                        </div>
                                                                        <div className="col-md-8 p-2">
                                                                            <p className="mb-0 coupon-name w-100" style={{ fontSize: '16px', fontWeight: '600' }}>{item.name}</p>
                                                                            <p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>{item.limitType}</p>
                                                                            <p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>
  {item.limitType === 'limited' ? item.limitNumber : ''}
</p>
<p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>{item.label}</p>
<p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>Total Use {item.usageCount}</p>
<p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>Event Name: {item.eventlabel}</p>
<p className="mb-0 coupon-point" style={{ fontSize: '14px', fontWeight: '600' }}>Ticket Name: {item.ticketlabel}</p>
                                                                            <div className="action-btn-div float-end mr-3" style={{ cursor: 'pointer' }}>
                                                                                <span onClick={() => CheckEdit(item._id)} className="text-success mx-1" style={{ border: '1px solid green', borderRadius: '20px', padding: '2px 20px', fontSize: '18px' }}><FiEdit2 /></span>
                                                                                <span onClick={() => CheckDelete(item._id)} className="text-danger" style={{ border: '1px solid red', borderRadius: '20px', padding: '2px 20px', fontSize: '18px' }}><FiTrash2 /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Norecord />
                                                )}
                                            </>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}
export default Dashboard;